<template>
  <section class="mb-3">
    <span class="d-inline-block headings--text subtitle-2">
      <slot name="label">
        {{ label }}
      </slot>
    </span>

    <v-tooltip
      v-if="tooltip"
      max-width="300"
      bottom
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          outlined
          x-small
          class="ml-2"
          v-bind="attrs"
          v-on="on"
          tabindex="-1"
        >
          <span class="primary--text">
            ?
          </span>
        </v-btn>
      </template>

      {{ tooltip }}
    </v-tooltip>
  </section>
</template>

<script>
export default {
  name: 'InputLabel',
  props: {
    label: {
      type: String,
      required: true,
      default: ''
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
